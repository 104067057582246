





































































































import Component from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';

import DataApiManager from '@/api/dataApiManager';
import { BaseVue } from '@/BaseVue';
import UiButton from '@/components/ui/UiButton.vue';
import UiCheckbox from '@/components/ui/UiCheckbox.vue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiDropdown from '@/components/ui/UiDropdown.vue';
import UiFormLabel from '@/components/ui/UiFormLabel.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiSelect2 from '@/components/ui/UiSelect2.vue';
import UiTextEdit from '@/components/ui/UiTextEdit.vue';
import UiTooltip from '@/components/ui/UiTooltip.vue';
import { toTitleCase } from '@/utils/stringUtils';

import { DataSvcICustomSchema, DataSvcSchema } from '../../../generated/data-svc';
import { columnTypes, schemaTypes } from './Schema.constants';

@Component({
  components: {
    UiButton,
    UiDropdown,
    UiFormLabel,
    UiCheckbox,
    UiTooltip,
    UiLoading,
    UiSelect2,
    UiTextEdit,
    UiDataTable,
  },
})
export default class SchemaEdit extends BaseVue {
  @Prop()
  public schemaItem!: DataSvcICustomSchema;

  public isValidInput = false;
  public status = true;
  public isParamNeeded = false;

  @Prop({ default: false })
  public readonly disabled!: boolean;

  @Emit('oneditSchemaButtonClick')
  async oneditSchemaButtonClick() {
    const response = await this.editSchema();
    return response;
  }

  async mounted() {
    try {
      this.newSchemaName = this.schemaItem.name;
      this.isParamNeeded = !!this.schemaItem.params;
      this.newParams = this.schemaItem.params ?? '';
      this.tableData = this.schemaItem.definition.fields.map((item) => ({
        name: item.name,
        type: {
          id: item.type,
          name: toTitleCase(item.type),
        },
      }));
      this.skipLeadingRows = `${this.schemaItem.skipLeadingRows}`;
      this.status = this.schemaItem.status === 'Enabled';
      this.validate();
    } catch (err) {
      console.log(err);
      this.showErrorSnackbar('Failed to Load Data for given schema');
    }
  }

  generateName(prefix = 'DataSource') {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hour = String(now.getHours()).padStart(2, '0');
    const minute = String(now.getMinutes()).padStart(2, '0');
    return `${prefix}-${year}-${month}-${day}-${hour}-${minute}`;
  }

  public isEditingSchema = false;
  public tableData = [
    { name: '', type: { id: 'string', name: 'String' } }, // Initial empty row
  ];

  public accountId = '';
  public newSchemaName = this.generateName('Schema');
  public newParams = 'accountId';
  public skipLeadingRows = '0';
  public newSchemaType = {
    id: 'transaction',
    name: 'Transaction',
  };

  public validateNameLength(name: string) {
    return name && name.length >= 3;
  }

  public get schemaList() {
    return schemaTypes;
  }

  public get columnTypeList() {
    return columnTypes;
  }

  public addRow() {
    this.tableData.push({ name: '', type: { id: 'string', name: 'String' } });
  }

  public removeRow(index: number) {
    this.tableData.splice(index, 1);
  }

  public validate() {
    if (this.validateNameLength(this.newSchemaName) && this.newSchemaType) {
      this.isValidInput = true;
    } else {
      this.isValidInput = false;
    }
  }

  public async editSchema() {
    this.validate();
    if (this.isValidInput) {
      this.isEditingSchema = true;
      const id = this.schemaItem.id;
      const fields: any[] = this.tableData.map((item) => ({ name: item.name, type: item.type.id }));

      const payload: DataSvcICustomSchema = {
        id,
        name: this.newSchemaName,
        orgID: this.orgId,
        status: this.status ? 'Enabled' : 'Disabled',
        definition: { fields },
        params: this.isParamNeeded ? this.newParams : '',
        skipLeadingRows: parseInt(this.skipLeadingRows, 10),
        schemaType: this.newSchemaType.id,
      };
      try {
        const ds = DataApiManager.getInstance();
        const res = await ds.customSchema(this.orgId, payload, { withCredentials: true });
        if (res.status === 200) {
          this.showSuccessSnackbar('Schema Updated Successfully');
          this.$emit('onEdited');
        } else {
          this.showErrorSnackbar('Error updating Schema');
        }
        return res;
      } catch (e) {
        this.showErrorSnackbar('Schema updation failed, Please try again later.');
        console.log('Error: ', e);
      } finally {
        this.isEditingSchema = false;
      }
    }
  }
}
