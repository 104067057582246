






































































































import { v4 as uuid } from 'uuid';
import Component from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';

import DataApiManager from '@/api/dataApiManager';
import { BaseVue } from '@/BaseVue';
import UiButton from '@/components/ui/UiButton.vue';
import UiCheckbox from '@/components/ui/UiCheckbox.vue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiDropdown from '@/components/ui/UiDropdown.vue';
import UiFormLabel from '@/components/ui/UiFormLabel.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiSelect2 from '@/components/ui/UiSelect2.vue';
import UiTextEdit from '@/components/ui/UiTextEdit.vue';
import UiTooltip from '@/components/ui/UiTooltip.vue';
import { secureRandomString } from '@/utils/stringUtils';

import { DataSvcDataSourceColumnDefinition, DataSvcICustomSchema, DataSvcSchema } from '../../../generated/data-svc';
import { columnTypes, schemaTypes } from './Schema.constants';

@Component({
  components: {
    UiButton,
    UiDropdown,
    UiFormLabel,
    UiCheckbox,
    UiTooltip,
    UiLoading,
    UiSelect2,
    UiTextEdit,
    UiDataTable,
  },
})
export default class SchemaCreate extends BaseVue {
  public isLoading = false;
  public isLoadingDataSources = false;

  @Prop({ default: null })
  public dataSourceId!: string;

  @Prop({ default: null })
  public schemaId!: string;

  public dataSource!: { id: string; name: string } | null;

  public isValidInput = false;
  public status = true;

  public isParamNeeded = true;

  @Prop({ default: false })
  public readonly disabled!: boolean;

  @Emit('oncreateSchemaButtonClick')
  async oncreateSchemaButtonClick() {
    const response = await this.createSchema();
    return response;
  }

  generateName(prefix = 'DataSource') {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hour = String(now.getHours()).padStart(2, '0');
    const minute = String(now.getMinutes()).padStart(2, '0');
    return `${prefix}-${year}-${month}-${day}-${hour}-${minute}`;
  }

  async loadDataSources() {
    this.isLoadingDataSources = true;
    try {
      const ds = DataApiManager.getInstance();
      const resp = await ds.listDataSources(this.orgId, { withCredentials: true });
      if (resp.status === 200) {
        const loaded = resp.data.items.filter((m) => m.type === 'Loaded');
        this.loadedDataSources = [];
        for (const l of loaded) {
          this.loadedDataSources.push({
            name: l.name,
            id: l.id,
          });
        }
      } else {
        const e = new Error('Failed to load data sources. Status Code:' + resp.status);
        this.showErrorSnackbar((e as Error).message);
      }
    } finally {
      this.isLoadingDataSources = false;
    }
  }

  public isCreatingSchema = false;
  public loadedDataSources: any = [];

  public tableData = [
    { name: '', type: { id: 'string', name: 'String' } }, // Initial empty row
  ];

  public accountId = '';
  public newSchemaName = this.generateName('Schema');
  public newParams = 'accountId';
  public skipLeadingRows = '0';
  public newSchemaType = {
    id: 'transaction',
    name: 'Transaction',
  };

  public schemas: DataSvcSchema[] = [];

  public validateNameLength(name: string) {
    return name.length >= 3;
  }

  public get schemaList() {
    return schemaTypes;
  }

  public get columnTypeList() {
    return columnTypes;
  }

  public addRow() {
    this.tableData.push({ name: '', type: { id: 'string', name: 'String' } });
  }

  public removeRow(index: number) {
    this.tableData.splice(index, 1);
  }

  public validate() {
    if (this.validateNameLength(this.newSchemaName) && this.newSchemaType) {
      this.isValidInput = true;
    } else {
      this.isValidInput = false;
    }
  }

  public async createSchema() {
    this.validate();
    if (this.isValidInput) {
      this.isCreatingSchema = true;
      const id = await secureRandomString(20);
      const fields: any[] = this.tableData.map((item) => ({ name: item.name, type: item.type.id }));

      const payload: DataSvcICustomSchema = {
        id,
        name: this.newSchemaName,
        orgID: this.orgId,
        status: this.status ? 'Enabled' : 'Disabled',
        definition: { fields },
        params: this.isParamNeeded ? this.newParams : null,
        skipLeadingRows: parseInt(this.skipLeadingRows, 10),
        schemaType: this.newSchemaType.id,
      };
      try {
        const ds = DataApiManager.getInstance();
        const res = await ds.customSchema(this.orgId, payload, { withCredentials: true });
        if (res.status === 200) {
          this.showSuccessSnackbar('Schema Created Successfully');
          this.$emit('onCreated');
        } else {
          this.showErrorSnackbar('Error creating Schema');
        }
        return res;
      } catch (e) {
        this.showErrorSnackbar('Schema creation failed, Please try again later.');
        console.log('Error: ', e);
      } finally {
        this.isCreatingSchema = false;
      }
    }
  }
}
