export const columnTypes = [
  { id: 'string', name: 'String' },
  { id: 'number', name: 'Number' },
  { id: 'bignumber', name: 'Big Number' },
  { id: 'boolean', name: 'Boolean' },
  { id: 'enum', name: 'Enumeration' },
  { id: 'date', name: 'Date' },
  { id: 'timestamp', name: 'Timestamp' },
];

export const schemaTypes = [
  {
    id: 'transaction',
    name: 'Transaction',
  },
  {
    id: 'wallet',
    name: 'Wallet',
  },
];
