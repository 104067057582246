






























































































































import Component from 'vue-class-component';

import DataApiManager from '@/api/dataApiManager';
import { BaseVue } from '@/BaseVue';
import UiButton from '@/components/ui/UiButton.vue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiDatePicker from '@/components/ui/UiDatePicker.vue';
import UiDropdown from '@/components/ui/UiDropdown.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiRadioGroup from '@/components/ui/UiRadioGroup.vue';
import UiSelect from '@/components/ui/UiSelect.vue';
import UiTextEdit from '@/components/ui/UiTextEdit.vue';
import UiTooltip from '@/components/ui/UiTooltip.vue';
import { toTitleCase } from '@/utils/stringUtils';

import { DataSvcICustomSchema, DataSvcSchema } from '../../../generated/data-svc';
import SchemaCreate from './SchemaCreate.vue';
import SchemaEdit from './SchemaEdit.vue';

@Component({
  components: {
    UiButton,
    UiDropdown,
    UiDataTable,
    UiTooltip,
    UiLoading,
    UiSelect,
    UiRadioGroup,
    UiTextEdit,
    UiDatePicker,
    SchemaCreate,
    SchemaEdit,
  },
})
export default class Schemas extends BaseVue {
  public isLoading = false;
  public isCreatingSchema = false;
  public isEditingSchema = false;
  public schemas: DataSvcSchema[] = [];
  public reportsLength = 0;
  public currentSchemaItem: DataSvcICustomSchema | null = null;

  public toTitleCase(str: string) {
    return toTitleCase(str);
  }

  readonly headers = [
    {
      id: 'icon',
      label: '',
      defaultVisibility: true,
    },
    {
      id: 'id',
      label: 'Id',
      defaultVisibility: true,
    },
    {
      id: 'name',
      label: 'Name',
      defaultVisibility: true,
    },
    {
      id: 'skipLeadingRows',
      label: 'Skip Leading Rows',
      defaultVisibility: true,
    },
    {
      id: 'params',
      label: 'Params',
      defaultVisibility: true,
    },
    {
      id: 'schemaType',
      label: 'Schema Type',
      defaultVisibility: true,
    },
    {
      id: 'status',
      label: 'Status',
      defaultVisibility: true,
    },
    {
      id: 'actions',
      label: 'Actions',
      defaultVisibility: true,
    },
  ];

  async mounted() {
    await this.loadRequiredData();
  }

  readonly dropdownValues = [
    {
      value: 'editSchema',
      label: 'Edit Schema',
    },
  ];

  handleEditSchema(schema: DataSvcICustomSchema) {
    this.currentSchemaItem = schema;
    this.isEditingSchema = true;
  }

  onDropdownSelect(valueSelected: any, feedItem: any) {
    switch (valueSelected) {
      case 'editSchema':
        this.handleEditSchema(feedItem);
        break;
    }
  }

  async loadRequiredData() {
    await this.loadCustomSchemas();
  }

  async onSchemaCreatedHandler() {
    this.isCreatingSchema = false;
    await this.loadRequiredData();
  }

  async onSchemaEditHandler() {
    this.isEditingSchema = false;
    await this.loadRequiredData();
  }

  async loadCustomSchemas() {
    this.isLoading = true;
    try {
      const ds = DataApiManager.getInstance();
      const resp = await ds.listCustomSchemas(this.orgId, { withCredentials: true });
      if (resp.status === 200) {
        this.schemas = resp.data.items;
      }
    } catch (e) {
      console.log('Problem loading schemas', e);
      this.showErrorSnackbar('Problem loading schemas');
    } finally {
      this.isLoading = false;
    }
  }
}
